// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("jquery")
global.jQuery = jQuery;
global.$ = jQuery;
global.quiz_timer = null;

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

import "bootstrap"

Rails.start()
Turbolinks.start()
ActiveStorage.start()

import { Users } from 'packs/users';
import { Quiz } from 'packs/quiz';

$( document ).on('turbolinks:load', function() {
  $(".messageBar").last().delay(4500).fadeOut();
  Users.initialize();
  Quiz.initialize();
  $(".show-nav").click(function(){
    if ($("nav:visible()").length) {
      $("nav").slideUp()
    } else {
      $("nav").slideDown()
    }
  })

  $("form").submit(function() {
    $(this).find("#recaptcha_token").val($("#recaptcha_badge").attr("data-recaptcha-token"));
  })

});
