export const Users = {

  initialize: function() {
    if ($("body.quiz_users").length) {
      $("[name*=role_id]").change(function(){
        var label_text = $("[name*=role_id]:checked").parent().find("label").text();

        $("#user_school_rspo_elementary, #user_school_rspo_non_elementary").hide();
        $("#user_municipality_elementary, #user_municipality_non_elementary").hide();

        $("#user_school_rspo_elementary select").attr("disabled", "disabled");
        $("#user_school_rspo_non_elementary select").attr("disabled", "disabled");
        $("#user_municipality_elementary select").attr("disabled", "disabled");
        $("#user_municipality_non_elementary select").attr("disabled", "disabled");

        if (label_text.match(/ponadpodstawowe/)) {
          $("#user_school_rspo_non_elementary").show();
          $("#user_municipality_non_elementary, #user_municipality_non_elementary select").show();
          $("#user_school_rspo_non_elementary select").removeAttr("disabled");
          $("#user_municipality_non_elementary select").removeAttr("disabled");
        } else if (label_text.match(/podstawowe/)){
          $("#user_school_rspo_elementary").show();
          $("#user_municipality_elementary").show();
          $("#user_school_rspo_elementary select").removeAttr("disabled");
          $("#user_municipality_elementary select").removeAttr("disabled");
        }
      });

      $("[name*=role_id]").trigger("change");

      $(function () { $("[data-bs-toggle='tooltip']").tooltip(); });

      $("#user_municipality_elementary select, #user_municipality_non_elementary select").change(function(){

        if ($("input[name='user[role_id]']:checked").val() == "3") {
          var target_select = $('#user_school_rspo');
        } else if ($("input[name='user[role_id]']:checked").val() == "4") {
          var target_select = $('#user_school_rspo_non_elementary select');

        }

        var teryt_str = $(this).find("option:selected")[0].dataset.teryt
        console.log(teryt_str)
        console.log(target_select)

        //target_select.attr('disabled', 'disabled')

        var list = target_select[0].children

        var selected;

        var codes;
        var code;

        if (teryt_str[0] == ",") {
          codes = teryt_str.split(",");
          codes.shift();
        } else {
          code = teryt_str;
        }

        console.log("codes: " + codes)
        console.log("code: " + code)

        for (var i = 0; i < list.length; i++) {
          var el = list[i]

          if ((codes && codes.includes(el.dataset.teryt)) || (code == el.dataset.teryt)) {
            el.style.display = "block";
            if (!selected) {
              selected = el;
              el.selected = true;
            }
          } else {
            el.style.display = "none";
          }
        }
        target_select.removeAttr('disabled')

      });

    }
  }

}
