export const Quiz = {

  initialize: function() {
    console.log("Initializing Quiz pack...")

    $("#quiz .answers button").click(function(event){
      var btn = this;

      $("#quiz .answers button").unbind("click")

      $.ajax({
        type: "POST",
        url: this.dataset.url,
        data: {option_id: this.dataset.id},
        success: function( data){
          var answered_mark = $(btn).parent().find(".correctness-mark")
          $(btn).addClass("selected")

          if (data.correct) {
            answered_mark.html("<i class='fa fa-check'></i>")
          } else {
            answered_mark.html("<i class='fa fa-times'></i>")
            var correct_mark = $(".answers button[data-id=" + data.correct_id + "]").parent().find(" .correctness-mark");
            correct_mark.html("<i class='fa fa-check'></i>")

          }
          $("#quiz .next-question").show();
        },
        dataType: "JSON",
      });
    });

    function incrementTimer(string) {
      var minutes = parseInt(string.split(":")[0])
      var seconds = parseInt(string.split(":")[1])

      if (seconds == 59) {
        var new_seconds = 0;
        var new_minutes = minutes + 1;
      } else {
        var new_seconds = seconds + 1;
        var new_minutes = minutes;
      }

      var new_minutes = new_minutes.toString()
      var new_seconds = new_seconds.toString()

      if (new_minutes.length == 1) {
        new_minutes = "0" + new_minutes;
      }
      if (new_seconds.length == 1) {
        new_seconds = "0" + new_seconds;
      }

      var new_string = new_minutes + ":" + new_seconds
      $("#timer").text(new_string)
    }


    clearInterval(quiz_timer);

    quiz_timer = setInterval(function() {
      incrementTimer($("#timer").text());
    }, 1000);

  }

}
